@import url(~antd/dist/antd.css);
@font-face {
  font-family: 'proxima-nova';
  src: url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'proxima-nova';
  src: url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: 'proxima-nova';
  src: url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'proxima-nova';
  src: url("https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/cebe0e/00000000000000003b9b3060/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300; }

@font-face {
  font-family: 'proxima-nova';
  src: url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: 'merriweather';
  src: url("https://use.typekit.net/af/627a5e/00000000000000003b9b38e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/627a5e/00000000000000003b9b38e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/627a5e/00000000000000003b9b38e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'merriweather';
  src: url("https://use.typekit.net/af/b50009/00000000000000003b9b38e2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/b50009/00000000000000003b9b38e2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/b50009/00000000000000003b9b38e2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'merriweather';
  src: url("https://use.typekit.net/af/bcff88/00000000000000003b9b38e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/bcff88/00000000000000003b9b38e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/bcff88/00000000000000003b9b38e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400; }

@font-face {
  font-family: 'satisfy';
  src: url("https://use.typekit.net/af/ddf2eb/00000000000000003b9b2940/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/ddf2eb/00000000000000003b9b2940/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/ddf2eb/00000000000000003b9b2940/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'nunito-sans';
  src: url("https://use.typekit.net/af/ed3645/00000000000000003b9b25de/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/ed3645/00000000000000003b9b25de/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/ed3645/00000000000000003b9b25de/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'nunito-sans';
  src: url("https://use.typekit.net/af/ba614d/00000000000000003b9b25e2/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/ba614d/00000000000000003b9b25e2/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/ba614d/00000000000000003b9b25e2/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'sfuitext';
  src: url("fonts/SFUIText-Regular.woff2") format("woff2"), url("fonts/SFUIText-Regular.woff") format("woff");
  font-display: auto;
  font-style: normal;
  font-weight: 400; }

* {
  font-family: nunito-sans, sans-serif;
  color: #333447; }

h1 {
  font-size: 2.5rem; }

h2 {
  font-size: 1.625rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  letter-spacing: -0.46px;
  line-height: 0.923;
  margin-bottom: 2rem; }

h3 {
  font-size: 1.375rem;
  font-family: proxima-nova, sans-serif;
  font-weight: 500;
  letter-spacing: -0.44px;
  line-height: 1.09; }

h4 {
  font-size: 1.125rem;
  letter-spacing: -0.35px; }

h5 {
  font-size: 1rem; }

h6 {
  font-size: 0.875rem; }

.clp-btn[disabled] {
  opacity: 0.6; }

.clp-btn.ant-btn {
  background: none;
  border-radius: 2px;
  border: 1px solid #0f0d18;
  padding: 10px 44px 10px;
  height: 48px;
  font-size: 12px; }
  .clp-btn.ant-btn span {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: text-top; }
  .clp-btn.ant-btn_mob-block {
    display: block;
    margin-left: 12px;
    margin-right: 12px;
    width: calc(100% - 12px * 2); }
  @media (min-width: 768px) {
    .clp-btn.ant-btn {
      font-size: 14px;
      display: inline-block;
      margin-left: 0;
      margin-right: 0;
      width: auto; }
      .clp-btn.ant-btn.ant-btn-block {
        width: 100%; } }
  .clp-btn.ant-btn * {
    color: #0f0d18;
    letter-spacing: 1px;
    text-align: center;
    line-height: 24px; }
  .clp-btn.ant-btn-sm {
    padding-left: 13px;
    padding-right: 16px; }
    @media (min-width: 768px) {
      .clp-btn.ant-btn-sm {
        padding-left: 16px; } }

.clp-btn.ant-btn.ant-btn-primary {
  background: #0f0d18; }
  .clp-btn.ant-btn.ant-btn-primary * {
    color: #ffffff; }

.clp-btn.ant-btn-link {
  background: transparent;
  border-color: transparent; }
  .clp-btn.ant-btn-link span {
    font-size: 12px;
    letter-spacing: 1px;
    text-decoration-line: underline;
    color: #0f0d18; }

.clp-card-info__header {
  font-family: proxima-nova, sans-serif;
  line-height: 24px;
  letter-spacing: -0.455px;
  color: #0f0d18;
  font-weight: 500; }
  .clp-card-info__header_size_sm {
    font-size: 16px; }
  .clp-card-info__header_size_md {
    font-size: 26px; }

.clp-card-step-by-step {
  display: flex;
  align-items: center;
  font-family: nunito-sans, sans-serif;
  font-weight: 600; }
  .clp-card-step-by-step--sub-label {
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 1.022px;
    color: #646464;
    font-family: proxima-nova, sans-serif; }
    @media (min-width: 768px) {
      .clp-card-step-by-step--sub-label {
        font-size: 14px; } }
  .clp-card-step-by-step--label {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1.168px;
    text-transform: uppercase;
    color: #0f0d18;
    font-family: proxima-nova, sans-serif; }
    @media (min-width: 768px) {
      .clp-card-step-by-step--label {
        font-size: 16px; } }

.clp-checkbox .ant-checkbox-inner {
  border-color: #646464 !important; }
  .clp-checkbox .ant-checkbox-inner:hover {
    border-color: #646464; }

.clp-checkbox .ant-checkbox-disabled {
  opacity: 0.6; }

.clp-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background: #646464;
  border-color: #646464; }
  .clp-checkbox .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #f5f5f5; }

.clp-checkbox .ant-checkbox-checked::after {
  border-color: #646464; }

.clp-checkbox .ant-checkbox + span {
  padding-left: 15px;
  font-size: 12px;
  color: #646464;
  line-height: 16px; }

.clp-container {
  padding: 0 16px; }
  @media (min-width: 768px) {
    .clp-container {
      padding: 0 14%; } }

.clp-content-img {
  transform: translate(0, -50%);
  position: absolute;
  top: 50%;
  left: 0; }
  .clp-content-img_mt_md {
    margin-top: 200px; }

.clp-content-row {
  overflow: hidden; }
  .clp-content-row_mb {
    margin-bottom: 155px; }
  .clp-content-row__img {
    transform: translate(0, -50%);
    position: absolute;
    top: 50%;
    left: 0; }
    .clp-content-row__img_mt_md {
      margin-top: 200px; }
  .clp-content-row__item_size_md {
    min-height: 500px; }
  .clp-content-row__item_size_lg {
    min-height: 800px; }

.clp-datepicker svg path {
  color: #646464; }

.clp-datepicker .ant-calendar-selected-day .ant-calendar-date,
.clp-datepicker .ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month,
.clp-datepicker .ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year,
.clp-datepicker .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade {
  background: #0f0d18;
  color: white;
  border-color: #0f0d18; }

.clp-datepicker .ant-calendar-today:not(.ant-calendar-selected-day) .ant-calendar-date {
  color: #0f0d18;
  border-color: #0f0d18; }

.clp-datepicker .ant-calendar-date:hover,
.clp-datepicker .ant-calendar-month-panel-month:hover,
.clp-datepicker .ant-calendar-year-panel-year:hover,
.clp-datepicker .ant-calendar-decade-panel-decade:hover {
  background: #0f0d181a;
  color: rgba(0, 0, 0, 0.65); }

.clp-datepicker .ant-calendar-today-btn,
.clp-datepicker .ant-calendar-my-select a {
  color: #0f0d18; }

.clp-datepicker__input .ant-calendar-picker-input.ant-input {
  background: transparent;
  box-shadow: none;
  outline: none;
  border-color: #646464 !important; }

.clp-datepicker__input .ant-input-disabled,
.clp-datepicker__input .ant-input-disabled + .anticon {
  opacity: 0.5; }

.clp-footer {
  padding: 32px 0 10px;
  border-top: 1px solid #e0ddd5;
  background: transparent;
  color: #1c1d1d;
  position: relative;
  overflow: hidden; }
  @media (min-width: 768px) {
    .clp-footer {
      overflow: inherit;
      padding-bottom: 80px; } }
  .clp-footer a:hover {
    text-decoration: underline; }
  .clp-footer__social {
    margin-top: 20px;
    margin-bottom: 20px; }
    @media (min-width: 768px) {
      .clp-footer__social {
        margin-top: 30px;
        margin-bottom: 30px; } }
    .clp-footer__social a {
      margin-right: 24px; }
  .clp-footer__img {
    position: absolute;
    top: 10px;
    right: -90px;
    pointer-events: none;
    width: 270px; }
    @media (min-width: 768px) {
      .clp-footer__img {
        width: 320px; } }
  .clp-footer__info {
    font-family: nunito-sans, sans-serif;
    line-height: 24px;
    letter-spacing: -0.245px;
    color: #1c1d1d; }

@media (max-width: 767px) {
  .clp-form-item {
    margin-left: 12px;
    margin-right: 12px; } }

.clp-form-item .ant-form-item-label {
  font-family: proxima-nova, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  padding-bottom: 0; }
  .clp-form-item .ant-form-item-label label {
    font-family: proxima-nova, sans-serif;
    color: #646464;
    font-size: 12px;
    line-height: 24px; }

.clp-form-item .ant-form-item-control {
  line-height: inherit; }

.clp-header {
  background: transparent;
  padding: 24px 0;
  overflow: hidden;
  height: 112px; }
  .clp-header .clp-horizontal-menu {
    padding-top: 10px; }
    @media (min-width: 768px) {
      .clp-header .clp-horizontal-menu {
        padding-top: 25px; } }

.clp-sub-menu {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  padding-top: 30px;
  background: #fdfcf7;
  z-index: 10; }
  .clp-sub-menu__img {
    max-height: 70vh;
    z-index: 2;
    left: 0;
    position: fixed; }
  .clp-sub-menu .ant-menu-root {
    background: transparent;
    text-align: right; }
  .clp-sub-menu .ant-menu-item-active,
  .clp-sub-menu .ant-menu-submenu-active,
  .clp-sub-menu .ant-menu-item-open,
  .clp-sub-menu .ant-menu-submenu-open,
  .clp-sub-menu .ant-menu-item-selected,
  .clp-sub-menu .ant-menu-submenu-selected {
    background: transparent !important; }
  .clp-sub-menu .ant-menu-item-selected,
  .clp-sub-menu .ant-menu-submenu-selected {
    font-weight: bold; }
  .clp-sub-menu .ant-menu-item,
  .clp-sub-menu .ant-menu-submenu-title {
    font-size: 14px;
    color: #1c1d1d; }
  @media (max-width: 767px) {
    .clp-sub-menu .ant-menu .ant-menu-item {
      margin-top: 0;
      margin-bottom: 0; } }

.ant-menu.clp-horizontal-menu {
  border-bottom: none;
  background: transparent;
  line-height: 36px;
  text-align: right;
  width: 100%; }
  .ant-menu.clp-horizontal-menu .ant-menu-item,
  .ant-menu.clp-horizontal-menu .ant-menu-submenu-title {
    font-size: 14px;
    color: #1c1d1d;
    vertical-align: inherit;
    padding: 0 12px; }
  .ant-menu.clp-horizontal-menu .ant-menu-item-active,
  .ant-menu.clp-horizontal-menu .ant-menu-item-open,
  .ant-menu.clp-horizontal-menu .ant-menu-item-selected {
    border-color: transparent;
    background: transparent; }
    .ant-menu.clp-horizontal-menu .ant-menu-item-active:after,
    .ant-menu.clp-horizontal-menu .ant-menu-item-open:after,
    .ant-menu.clp-horizontal-menu .ant-menu-item-selected:after {
      content: '';
      height: 1px;
      display: block;
      margin-top: 2px;
      background: #0f0d184d; }
  .ant-menu.clp-horizontal-menu .ant-menu-submenu {
    border-color: transparent;
    vertical-align: inherit; }
  .ant-menu.clp-horizontal-menu .ant-menu-item-selected,
  .ant-menu.clp-horizontal-menu .ant-menu-submenu-selected {
    font-weight: bold; }

.clp-antd-submenu .ant-menu-item,
.clp-antd-submenu .ant-dropdown-menu-item {
  background: transparent !important; }
  .clp-antd-submenu .ant-menu-item-active, .clp-antd-submenu .ant-menu-item-selected,
  .clp-antd-submenu .ant-dropdown-menu-item-active,
  .clp-antd-submenu .ant-dropdown-menu-item-selected {
    font-size: 14px;
    color: #1c1d1d;
    font-weight: bold; }

.clp-antd-submenu_xs .ant-menu {
  min-width: 60px; }

.ant-menu-submenu-popup:not(.clp-antd-submenu) {
  display: none; }

.ant-menu-submenu-arrow:after,
.ant-menu-submenu-arrow:before {
  background: #646464 !important; }

.ant-menu-item,
.ant-menu-item:active,
.clp-sub-menu .ant-menu-item-active,
.ant-menu-submenu-title:active {
  background: transparent !important; }

.ant-menu-item:after {
  border-color: transparent !important; }

.submenu_transparent .ant-menu {
  background: transparent !important; }

.clp-input.ant-input, .clp-input.ant-input[disabled]:hover {
  border-color: transparent;
  background: transparent;
  border-bottom-color: #646464;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  color: #0f0d18;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: none;
  outline: none; }

.clp-input.ant-input[disabled] {
  opacity: 0.6; }

.has-error .clp-input.ant-input {
  border-bottom-color: #ff4d4f; }
  .has-error .clp-input.ant-input:not([disabled]):hover, .has-error .clp-input.ant-input:focus {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    border-bottom-color: #ff4d4f; }

.has-error .ant-select-selection {
  background: transparent !important;
  border-color: #ff4d4f !important;
  box-shadow: none !important; }
  .has-error .ant-select-selection .ant-input, .has-error .ant-select-selection .ant-input-group-addon {
    border-bottom-color: #ff4d4f !important; }

.clp-input .ant-input-group .ant-input,
.clp-input .ant-input-group .ant-input[disabled]:hover {
  border-color: #646464 !important;
  background: transparent;
  color: #0f0d18;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: none;
  outline: none;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0; }
  .clp-input .ant-input-group .ant-input + .ant-input-group-addon,
  .clp-input .ant-input-group .ant-input[disabled]:hover + .ant-input-group-addon {
    padding-left: 0; }

.clp-input .ant-input-group.ant-input[disabled] {
  opacity: 0.6; }

.clp-input .ant-input-group .ant-input-group-addon {
  border-color: #646464;
  background: transparent;
  padding-bottom: 2px;
  padding-right: 5px;
  padding-left: 5px;
  color: #0f0d18 !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0; }
  .clp-input .ant-input-group .ant-input-group-addon + input {
    padding-left: 0; }

.clp-language-selector {
  position: fixed;
  bottom: 0; }
  .clp-language-selector .ant-menu-submenu-title {
    background: transparent; }
  .clp-language-selector__popup {
    transform: translate(0px, -110%) !important;
    top: 100vh !important; }

.clp-logo img {
  width: 60px;
  height: 60px;
  margin-right: 16px;
  float: left; }

.clp-logo__label {
  text-transform: uppercase;
  text-align: left;
  display: inline-block;
  padding-top: 7px;
  font-size: 16px;
  line-height: 16px; }
  @media (min-width: 768px) {
    .clp-logo__label {
      font-size: 22px;
      line-height: 24px; } }

.clp-logo__sub-label {
  font-family: proxima-nova;
  font-weight: 500;
  font-size: 12px;
  color: #646464;
  display: block;
  text-transform: none;
  line-height: 16px; }
  @media (min-width: 768px) {
    .clp-logo__sub-label {
      font-size: 14px;
      line-height: 24px; } }

.clp-main {
  padding-bottom: 28px;
  min-height: calc(99vh - 112px - 251px); }
  @media (min-width: 768px) {
    .clp-main {
      padding-top: 20px;
      padding-bottom: 60px; } }

.clp-nav a {
  color: #1c1d1d;
  letter-spacing: -0.25px;
  line-height: 24px;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  display: block;
  margin-bottom: 10px;
  margin-right: 0; }
  @media (min-width: 768px) {
    .clp-nav a {
      display: inline-block;
      margin-right: 32px;
      margin-bottom: 0; } }
  .clp-nav a:hover, .clp-nav a.active, .clp-nav a:active {
    color: black; }
  .clp-nav a:active, .clp-nav a.active {
    border-bottom: 1px solid #e0ddd5; }
  .clp-nav a.active {
    font-weight: 700; }
  .clp-nav a svg {
    margin-right: 8px;
    height: 19px;
    width: 19px; }

.clp-paragraph {
  font-family: nunito-sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: -0.28px;
  color: #646464; }
  .clp-paragraph_size_xs {
    font-size: 12px;
    line-height: 18px; }
  .clp-paragraph_size_sm {
    font-size: 16px;
    line-height: 24px; }
  .clp-paragraph_size_md {
    font-size: 18px;
    line-height: 26px; }

.clp-phone-input__container_block {
  width: 100%; }

.clp-phone-input__container_disabled {
  opacity: 0.6; }

.clp-phone-input__container .selected-flag {
  background-color: transparent !important;
  display: flex;
  justify-content: flex-end; }
  .clp-phone-input__container .selected-flag:after {
    content: '';
    position: absolute;
    bottom: -1px;
    right: 0;
    width: 19px;
    height: 100%;
    transform: translateX(50%);
    border-bottom: 1px solid #fdfcf7; }

.clp-phone-input__container .selected-dial-code {
  color: #0f0d18;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-top: 2px;
  padding-left: 33px !important; }

.clp-phone-input__container .iti-arrow {
  position: absolute;
  left: 0; }

.clp-phone-input__container .iti-flag {
  left: 12px; }

.clp-phone-input__container .country-list {
  width: 100%;
  margin-top: 3px;
  overflow: auto;
  border-radius: 2px;
  border: none;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); }
  .clp-phone-input__container .country-list li {
    overflow-x: hidden;
    white-space: pre-wrap;
    font-size: 16px;
    padding: 12px !important; }
    .clp-phone-input__container .country-list li span {
      font-family: proxima-nova, sans-serif; }
  .clp-phone-input__container .country-list .flag-box {
    margin-right: 9px !important; }

.clp-phone-input__input {
  border-color: transparent;
  background: transparent;
  border-bottom-color: #646464;
  border-radius: 0;
  border-bottom-width: 1px;
  padding-left: 0;
  padding-right: 0;
  color: #0f0d18;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  box-shadow: none;
  outline: none; }
  .clp-phone-input__input_block {
    width: 100%; }

.iti-sdc-2 > .clp-phone-input__input {
  padding-left: 80px !important; }

.iti-sdc-3 > .clp-phone-input__input {
  padding-left: 90px !important; }

.iti-sdc-4 > .clp-phone-input__input {
  padding-left: 100px !important; }

.iti-sdc-5 > .clp-phone-input__input {
  padding-left: 110px !important; }

.iti-sdc-4 .selected-flag {
  width: 90px !important; }

.iti-sdc-5 .selected-flag {
  width: 100px !important; }

.clp-select_input_bold .ant-select-selection-selected-value, .clp-select_input_bold .ant-select-search__field {
  color: #0f0d18;
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px; }

.clp-select_width_sm {
  width: 100px; }

.clp-select_width_md {
  width: 200px; }

.clp-select_bordered_bottom .ant-select-selection {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0; }

.clp-select_bordered_bottom .ant-select-selection__rendered {
  margin-left: 0; }

.clp-select .ant-select-selection {
  box-shadow: none;
  outline: none;
  border-color: #646464; }
  .clp-select .ant-select-selection svg {
    fill: #646464; }

.clp-select .ant-select-selection {
  background: transparent; }

.clp-select__dropdown .ant-select-dropdown-menu-item:hover,
.clp-select__dropdown .ant-select-dropdown-menu-item-active,
.clp-select__dropdown .ant-select-dropdown-menu-item-selected {
  background: #0f0d181a !important; }

@media (max-width: 767px) {
  .clp-select-currency {
    display: flex; } }

.clp-select-currency .clp-input {
  max-width: 100px;
  border-color: #646464 !important;
  border-radius: 2px 0 0 2px;
  padding-left: 10px;
  padding-right: 10px; }
  @media (max-width: 767px) {
    .clp-select-currency .clp-input {
      flex: 1;
      max-width: none; } }

.clp-select-currency .ant-select-selection {
  border-radius: 0 2px 2px 0;
  border-left: 0; }

.clp-steps .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon,
.clp-steps .ant-steps-item-process .ant-steps-item-icon,
.clp-steps .ant-steps-item-finish .ant-steps-item-icon {
  background: #1d1d1b;
  border-color: transparent; }
  .clp-steps .ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon .ant-steps-icon,
  .clp-steps .ant-steps-item-process .ant-steps-item-icon .ant-steps-icon,
  .clp-steps .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
    color: white !important;
    font-size: inherit;
    padding-right: 1px; }

.clp-steps .ant-steps-item-wait .ant-steps-item-icon {
  background: #e5e5e5; }
  .clp-steps .ant-steps-item-wait .ant-steps-item-icon .ant-steps-icon {
    color: #646464 !important;
    font-size: inherit; }
  .clp-steps .ant-steps-item-wait .ant-steps-item-icon::after {
    border-color: #e5e5e5; }

.clp-steps .ant-steps-item-process .ant-steps-item-icon {
  margin: 3px; }

.clp-steps .ant-steps-item-icon {
  margin: 0; }
  .clp-steps .ant-steps-item-icon::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border: 1px solid #1d1d1b;
    border-radius: 50%; }

.clp-steps .ant-steps-icon {
  font-family: proxima-nova, sans-serif; }

.clp-steps .ant-steps-item {
  display: inline-block; }

.clp-steps .ant-steps-item-content {
  display: none;
  min-height: 38px !important; }

.ant-steps-vertical .ant-steps-item {
  left: 50%;
  transform: translateX(-50%); }

.ant-steps-vertical .clp-steps:not(:last-child) .ant-steps-item {
  margin-bottom: 40px;
  position: relative; }
  .ant-steps-vertical .clp-steps:not(:last-child) .ant-steps-item::after {
    content: '';
    position: absolute;
    width: 1px;
    height: 40px;
    left: 50%;
    top: 100%;
    background: black; }

.ant-steps-vertical .clp-steps:not(:last-child) .ant-steps-item-wait::after {
  opacity: 0.1; }

.ant-steps-horizontal.ant-steps-label-horizontal {
  display: flex;
  justify-content: space-between; }

.ant-steps-horizontal .clp-steps {
  z-index: 1; }
  .ant-steps-horizontal .clp-steps .ant-steps-item-icon {
    margin: 3px;
    width: 32px !important; }
    .ant-steps-horizontal .clp-steps .ant-steps-item-icon::after {
      background: white;
      z-index: 0; }
  .ant-steps-horizontal .clp-steps .ant-steps-item-icon::after {
    z-index: -1; }

.ant-steps-horizontal::after {
  content: '';
  position: absolute;
  background: black;
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0; }

.clp-tabs .ant-tabs-bar:not(.ant-tabs-card-bar) .ant-tabs-tab:first-child {
  padding-left: 0; }

.clp-tabs .ant-tabs-nav .ant-tabs-tab-active {
  color: #1c1d1d;
  font-weight: bold; }

.clp-tabs .ant-tabs-nav .ant-tabs-tab {
  color: #1c1d1d;
  margin-right: 10px; }
  @media (min-width: 768px) {
    .clp-tabs .ant-tabs-nav .ant-tabs-tab {
      margin-right: 25px; } }

.clp-tabs .ant-tabs-nav .ant-tabs-tab-disabled {
  opacity: 0.3; }

.clp-tabs .ant-tabs-nav .ant-tabs-ink-bar {
  background: transparent; }

.clp-tabs .ant-tabs-bar {
  border-color: #0f0d18; }
  .clp-tabs .ant-tabs-bar.ant-tabs-card-bar {
    border-color: #b6b4b4; }
    .clp-tabs .ant-tabs-bar.ant-tabs-card-bar .ant-tabs-tab {
      border-color: #b6b4b4;
      background: #ededed;
      color: rgba(0, 0, 0, 0.65); }
      .clp-tabs .ant-tabs-bar.ant-tabs-card-bar .ant-tabs-tab-active {
        border-bottom-color: #fdfcf7;
        background: transparent;
        color: black;
        font-weight: normal; }

.clp-textarea.ant-input, .clp-textarea.ant-input[disabled]:hover {
  border-color: #646464;
  background: transparent;
  border-radius: 2px;
  padding: 8px 16px;
  color: #0f0d18;
  font-family: nunito-sans, sans-serif;
  font-size: 12px;
  line-height: 16px;
  box-shadow: none;
  outline: none; }

.clp-textarea.ant-input[disabled] {
  opacity: 0.6; }

.clp-timepicker .ant-time-picker-input[disabled],
.clp-timepicker .ant-time-picker-input[disabled] + .ant-time-picker-icon {
  opacity: 0.5; }
  .clp-timepicker .ant-time-picker-input[disabled]:hover,
  .clp-timepicker .ant-time-picker-input[disabled] + .ant-time-picker-icon:hover {
    border-color: #646464; }

.clp-timepicker .ant-time-picker-input {
  background: transparent;
  border-color: #646464;
  color: #0f0d18; }

.clp-timepicker svg {
  fill: #646464; }

.clp-timepicker .ant-time-picker-panel-input {
  background: red; }

.clp-timepicker-popup .ant-time-picker-panel-input {
  color: #0f0d18; }

.clp-timepicker-popup .ant-time-picker-panel-select li:hover {
  background: #f5f5f5; }

.clp-timepicker-popup .ant-time-picker-panel-select li:focus {
  color: #333447; }

.clp-typography-text {
  font-family: proxima-nova, sans-serif;
  color: #646464;
  font-weight: normal;
  display: inline-block; }
  .clp-typography-text_size_sm {
    font-size: 12px;
    line-height: 19px; }
    @media (min-width: 768px) {
      .clp-typography-text_size_sm {
        font-size: 16px;
        line-height: 24px; } }
  .clp-typography-text_size_md {
    font-size: 12px;
    line-height: 19px; }
    @media (min-width: 768px) {
      .clp-typography-text_size_md {
        font-size: 18px;
        line-height: 26px; } }
  .clp-typography-text_size_lg {
    font-size: 14px;
    line-height: 26px; }
    @media (min-width: 768px) {
      .clp-typography-text_size_lg {
        letter-spacing: -0.2px;
        font-size: 18px; } }

h1.clp-typography-title,
h2.clp-typography-title,
h3.clp-typography-title,
h4.clp-typography-title {
  font-family: proxima-nova, sans-serif;
  color: #0f0d18;
  font-weight: 500; }
  h1.clp-typography-title_size_xs,
  h2.clp-typography-title_size_xs,
  h3.clp-typography-title_size_xs,
  h4.clp-typography-title_size_xs {
    font-size: 14px;
    line-height: 18px; }
    @media (min-width: 768px) {
      h1.clp-typography-title_size_xs,
      h2.clp-typography-title_size_xs,
      h3.clp-typography-title_size_xs,
      h4.clp-typography-title_size_xs {
        font-size: 16px;
        line-height: 24px; } }
  h1.clp-typography-title_size_sm,
  h2.clp-typography-title_size_sm,
  h3.clp-typography-title_size_sm,
  h4.clp-typography-title_size_sm {
    font-size: 14px;
    line-height: 18px; }
    @media (min-width: 768px) {
      h1.clp-typography-title_size_sm,
      h2.clp-typography-title_size_sm,
      h3.clp-typography-title_size_sm,
      h4.clp-typography-title_size_sm {
        font-size: 26px;
        line-height: 24px; } }
  h1.clp-typography-title_size_md,
  h2.clp-typography-title_size_md,
  h3.clp-typography-title_size_md,
  h4.clp-typography-title_size_md {
    font-size: 18px;
    line-height: 24px; }
    @media (min-width: 768px) {
      h1.clp-typography-title_size_md,
      h2.clp-typography-title_size_md,
      h3.clp-typography-title_size_md,
      h4.clp-typography-title_size_md {
        font-size: 26px;
        line-height: 24px; } }

.icon-expand {
  font-size: 20px !important;
  padding: 10px 15px 10px 5px; }

.w-100p {
  width: 100% !important; }

.ta-l {
  text-align: left !important; }

.ta-r {
  text-align: right !important; }

.ta-c {
  text-align: center !important; }

.tt-u {
  text-transform: uppercase !important; }

.p-0 {
  padding: 0 !important; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0px !important; }

.pt-0 {
  padding-top: 0px !important; }

.mb-0 {
  margin-bottom: 0px !important; }

.pb-0 {
  padding-bottom: 0px !important; }

.ml-0 {
  margin-left: 0px !important; }

.pl-0 {
  padding-left: 0px !important; }

.mr-0 {
  margin-right: 0px !important; }

.pr-0 {
  padding-right: 0px !important; }

.mt-2 {
  margin-top: 2px !important; }

.pt-2 {
  padding-top: 2px !important; }

.mb-2 {
  margin-bottom: 2px !important; }

.pb-2 {
  padding-bottom: 2px !important; }

.ml-2 {
  margin-left: 2px !important; }

.pl-2 {
  padding-left: 2px !important; }

.mr-2 {
  margin-right: 2px !important; }

.pr-2 {
  padding-right: 2px !important; }

.mt-5 {
  margin-top: 5px !important; }

.pt-5 {
  padding-top: 5px !important; }

.mb-5 {
  margin-bottom: 5px !important; }

.pb-5 {
  padding-bottom: 5px !important; }

.ml-5 {
  margin-left: 5px !important; }

.pl-5 {
  padding-left: 5px !important; }

.mr-5 {
  margin-right: 5px !important; }

.pr-5 {
  padding-right: 5px !important; }

.mt-8 {
  margin-top: 8px !important; }

.pt-8 {
  padding-top: 8px !important; }

.mb-8 {
  margin-bottom: 8px !important; }

.pb-8 {
  padding-bottom: 8px !important; }

.ml-8 {
  margin-left: 8px !important; }

.pl-8 {
  padding-left: 8px !important; }

.mr-8 {
  margin-right: 8px !important; }

.pr-8 {
  padding-right: 8px !important; }

.mt-10 {
  margin-top: 10px !important; }

.pt-10 {
  padding-top: 10px !important; }

.mb-10 {
  margin-bottom: 10px !important; }

.pb-10 {
  padding-bottom: 10px !important; }

.ml-10 {
  margin-left: 10px !important; }

.pl-10 {
  padding-left: 10px !important; }

.mr-10 {
  margin-right: 10px !important; }

.pr-10 {
  padding-right: 10px !important; }

.mt-12 {
  margin-top: 12px !important; }

.pt-12 {
  padding-top: 12px !important; }

.mb-12 {
  margin-bottom: 12px !important; }

.pb-12 {
  padding-bottom: 12px !important; }

.ml-12 {
  margin-left: 12px !important; }

.pl-12 {
  padding-left: 12px !important; }

.mr-12 {
  margin-right: 12px !important; }

.pr-12 {
  padding-right: 12px !important; }

.mt-15 {
  margin-top: 15px !important; }

.pt-15 {
  padding-top: 15px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.pb-15 {
  padding-bottom: 15px !important; }

.ml-15 {
  margin-left: 15px !important; }

.pl-15 {
  padding-left: 15px !important; }

.mr-15 {
  margin-right: 15px !important; }

.pr-15 {
  padding-right: 15px !important; }

.mt-20 {
  margin-top: 20px !important; }

.pt-20 {
  padding-top: 20px !important; }

.mb-20 {
  margin-bottom: 20px !important; }

.pb-20 {
  padding-bottom: 20px !important; }

.ml-20 {
  margin-left: 20px !important; }

.pl-20 {
  padding-left: 20px !important; }

.mr-20 {
  margin-right: 20px !important; }

.pr-20 {
  padding-right: 20px !important; }

.mt-25 {
  margin-top: 25px !important; }

.pt-25 {
  padding-top: 25px !important; }

.mb-25 {
  margin-bottom: 25px !important; }

.pb-25 {
  padding-bottom: 25px !important; }

.ml-25 {
  margin-left: 25px !important; }

.pl-25 {
  padding-left: 25px !important; }

.mr-25 {
  margin-right: 25px !important; }

.pr-25 {
  padding-right: 25px !important; }

.mt-30 {
  margin-top: 30px !important; }

.pt-30 {
  padding-top: 30px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.pb-30 {
  padding-bottom: 30px !important; }

.ml-30 {
  margin-left: 30px !important; }

.pl-30 {
  padding-left: 30px !important; }

.mr-30 {
  margin-right: 30px !important; }

.pr-30 {
  padding-right: 30px !important; }

.mt-50 {
  margin-top: 50px !important; }

.pt-50 {
  padding-top: 50px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.pb-50 {
  padding-bottom: 50px !important; }

.ml-50 {
  margin-left: 50px !important; }

.pl-50 {
  padding-left: 50px !important; }

.mr-50 {
  margin-right: 50px !important; }

.pr-50 {
  padding-right: 50px !important; }

.mt-100 {
  margin-top: 100px !important; }

.pt-100 {
  padding-top: 100px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.pb-100 {
  padding-bottom: 100px !important; }

.ml-100 {
  margin-left: 100px !important; }

.pl-100 {
  padding-left: 100px !important; }

.mr-100 {
  margin-right: 100px !important; }

.pr-100 {
  padding-right: 100px !important; }

.d_none {
  display: none; }

@media (max-width: 767px) {
  .hidden_mob {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden_desk {
    display: none !important; } }

@media (max-width: 767px) {
  .mob-text_center {
    text-align: center !important; } }

@media (max-width: 767px) {
  .mob-form-wrapper {
    margin-left: 12px;
    margin-right: 12px;
    width: calc(100% - 12px * 2); } }

.max-w_100 {
  max-width: 100%; }

.c-pointer {
  cursor: pointer; }

.t_bold {
  font-weight: 800; }

.t_normal {
  font-weight: normal; }
